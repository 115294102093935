import React from "react"
import { Link, graphql } from "gatsby"
// import Img from "gatsby-image"
import {
  // Flex,
  // Stack,
  Box,
  Text,
  Heading,
  Button,
  ButtonGroup,
  // Grid,
  Divider,
  // IconButton,
  // Icon,
  // Avatar,
} from "@chakra-ui/core"

import Layout from '../components/Layout'
import ImageSlider from "../components/ImageSlider"
// import Card from "../components/Card"


// The home page component
export default function Home({ data }) {
  console.log('homepage data', data);

  const images = [{
    id: '1',
    url: data.file,
    caption: 'school of birds'
  }];

  let subtitle = "School of Birds is an organization which aims to create awareness and knowledge about importance of biodiversity, local wildlife and conservation, through bird-watching. Incorporating techniques from STEM education and Social Emotional Learning.";
  return (
    <Layout>
      <ImageSlider images={images} autoplay={false}/>
      <Divider/>
      <HeroText siteTitle="School Of Birds" mainTagLine={subtitle} />
      <Divider/>
      <Heading mt={10} mb={2}> What we do </Heading>
      <Text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ornare molestie risus id bibendum. Vivamus eget mauris non ante interdum lacinia. Praesent at ultrices ipsum, at vestibulum lectus. Suspendisse urna lorem, aliquet eget gravida ut, finibus pharetra velit. Aenean arcu eros, tempus eget viverra ut, scelerisque et ipsum. Ut hendrerit ultricies urna sit amet lobortis. Aenean euismod leo dictum mauris finibus facilisis. Morbi venenatis euismod dui, id tristique purus mattis eu. Integer id posuere ipsum. Sed tempor congue sollicitudin. Aliquam et luctus velit, ut dictum nulla. Praesent suscipit varius aliquet.
      </Text>
      <Heading mt={10} mb={2}> Our Projects </Heading>
      <Text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ornare molestie risus id bibendum. Vivamus eget mauris non ante interdum lacinia. Praesent at ultrices ipsum, at vestibulum lectus. Suspendisse urna lorem, aliquet eget gravida ut, finibus pharetra velit. Aenean arcu eros, tempus eget viverra ut, scelerisque et ipsum. Ut hendrerit ultricies urna sit amet lobortis. Aenean euismod leo dictum mauris finibus facilisis. Morbi venenatis euismod dui, id tristique purus mattis eu. Integer id posuere ipsum. Sed tempor congue sollicitudin. Aliquam et luctus velit, ut dictum nulla. Praesent suscipit varius aliquet.
      </Text>
      <Divider/>
    </Layout>
  )
}

const HeroText = ({siteTitle, mainTagLine, caption}) => (
  <Box p={4} maxW={1000} margin="0 auto" color="#402515">
    <Text p={2} fontSize="46px" fontWeight="bold" textAlign={"center"} > {siteTitle} </Text>
    <Text p={2} fontSize="xl" >
      {mainTagLine}{" "}
    </Text>
    <ButtonGroup spacing={8} my={5} >
      <Button variantColor="black" rightIcon="info" size="lg" variant="outline">
        <Link to="/about"> Learn More </Link>
      </Button>
      <Button variantColor="brown" rightIcon="arrow-forward" size="lg" variant="outline">
        <Link to="/join-us"> Get Involved </Link>
      </Button>
    </ButtonGroup>
  </Box>
)


export const query = graphql`
query GetHomePage {
  file(relativePath: {eq: "landing-01.jpg"}) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

/*
  # fetch homepage static content
  homepage: allStrapiHome {
    edges {
      node {
        siteTitle
        frontMatter
        imageSlider {
          Slide {
            id
            Caption
            Picture {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }

 */
