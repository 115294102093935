import React from "react"
import Slider from "react-slick"
import Img from "gatsby-image"
import {Box, IconButton } from "@chakra-ui/core"

const ImageSlider = ({ images, autoplay }) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: autoplay,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <Box>
        <Slider {...settings} padding={10}>
        {images.map((image) => {
          return (
            <Box key={image.id}>
                <Img fluid={image.url.childImageSharp.fluid} alt={image.caption} maxH={500}/>
            </Box>
          )
        })}
      </Slider>
    </Box>
  )
}

function NextArrow(props) {
  const { onClick } = props;
  return <CustomArrow
           icon="arrow-forward"
           ariaLabel="Next"
           customStyle={{ top: '45%', right: '10px', }}
           onClick={onClick}
         />
}

function PrevArrow(props) {
  const { onClick } = props;
  return <CustomArrow
           icon="arrow-back"
           ariaLabel="Previous"
           customStyle={{ top: '45%', left: '10px', }}
           onClick={onClick}
         />
}

const CustomArrow = ({icon, ariaLabel, customStyle, onClick}) => (
  <IconButton
    icon={icon}
    aria-label={ariaLabel}
    variant="outline"
    isRound={true}
    variantColor="whiteAlpha"
    fontSize="2em"
    _focus={{boxShadow: '0 0 0 2px #eee'}}
    size="lg"
    style={{ position: 'absolute', zIndex: 10, ...customStyle}}
    onClick={onClick}
  />
)

export default ImageSlider
